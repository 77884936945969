/* global G */
/* eslint-disable no-unused-vars,object-curly-newline,implicit-arrow-linebreak */
import { FormControl, Grid } from '@mui/material'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useMemoRef from '@platform/react/hook/useMemoRef'
import ApplicationContext from '@platform/react/context/application'
import { useEventCallback, useEventHandler } from '@platform/react/hook'
import GaiaUploadLabel from 'ui/Component/Attachment/Upload/Label'
import GaiaAddMoreButton from 'ui/Component/Attachment/Upload/AddMoreButton'
import PlatformEvent from 'lib/util/event'

const handleDragOver = (e) => {
  e.preventDefault()
  e.stopPropagation()
  e.dataTransfer.dropEffect = 'copy'
}

/**
 * Synchronizes the files in the group, with the list of files of the input element.
 * This allows us to delete a file and then reselect it.
 *
 * @param {Object[]} groupItems
 * @param {HTMLInputElement} input
 */
const synchronizeFileList = (groupItems, input) => {
  const groupItemNames = groupItems.map(x => x.key)
  const inputFiles = input?.files || []
  const fileList = new DataTransfer()

  for (let i = 0; i < inputFiles.length; i++) {
    const file = inputFiles[i]
    if (groupItemNames.includes(file.name)) {
      fileList.items.add(file)
    }
  }

  // eslint-disable-next-line no-param-reassign
  input.files = fileList.files
}

/**
 * AttachmentUpload component
 *
 * Component used to upload files by either dropping or selecting them.
 *
 * @param {Object} forwardedRef     a reference to the input field
 * @param {string} group
 * @param {Object} events           an object containing the events to be used by the component
 * @param {string} label            a string to be used as the label's text
 * @param {string} text             a string to be used as the add more button's text
 * @param {string|boolean} single   whether a single or more files are allowed to be uploaded
 * @param {number} minHeight        the minimum number of theme's spacings of the label's height
 * @param {boolean} [standalone]    boolean whether the component is in standalone mode
 *                                  meaning no attachments will be displayed inside it
 * @param {Object} props            an object containing additional component properties
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line max-len
const AttachmentUpload = ({ forwardedRef, group, events, label, text, single, minHeight, standalone = false, ...props }) => {
  const { eventBus } = useContext(ApplicationContext)
  const [dragOver, setDragOver] = useState(false)
  const [depth, setDepth] = useState(0)
  const [hasAttachments, setHasAttachments] = useState(false)
  const [undo, setUndo] = useState(true)
  const [filters, setFilters] = useState(false)

  const onChange = useCallback((event) => {
    events.onChange(new PlatformEvent(event, { files: event.target.files, undo }))
  }, [undo])

  const _eventName = useRef(eventBus.type(G.ATTACHMENT, G.DONE, group))
  const _eventHandler = useEventCallback((
    { detail: { [G.DATA]: items, [G.STATE]: {
      [G.UNDO]: shouldSetUndo,
      [G.FILTER]: allowedFilters,
    } } },
  ) => {
    synchronizeFileList(items, forwardedRef?.current)
    setHasAttachments(items && items.length > 0 && items[0].key !== -1)
    setUndo(shouldSetUndo)
    setFilters(allowedFilters)
  })
  useEventHandler(eventBus, _eventName.current, _eventHandler)

  useEffect(() => {
    const eventType = eventBus.type(G.ATTACHMENT, G.CACHE, group)
    const data = { [G.DATA]: { [G.DATA]: false } }
    eventBus.dispatch(eventType, data)
  }, [])

  return (
    <Grid
      item
      container
      component={FormControl}
      direction={'row'}
      alignContent={'stretch'}
      justifyContent={'center'}
      rowSpacing={1}
      sx={{
        border: '0.15rem dashed rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        color: 'text.primary',
        flex: 1,
        // activates on drag over
        background: dragOver && `repeating-linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.1) 10%,
          rgba(0, 0, 0, 0.2) 10%,
          rgba(0, 0, 0, 0.2) 20%
        )`,
        padding: '1%',
      }}
      onChange={onChange}
      onDrop={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragOver(false)
        setDepth(0);
        (!single || (single && !hasAttachments)) && onChange(e)
      }}
      onDragOver={e => handleDragOver(e)}
      onDragEnter={(e) => {
        setDepth(depth + 1)
        setDragOver(true)
      }}
      onDragLeave={(e) => {
        const newDepth = depth - 1
        setDepth(newDepth)
        newDepth === 0 && setDragOver(false)
      }}
      xs={12}
      md={12}
      xl={12}
    >

      {!standalone && props.children}

      <GaiaUploadLabel
        text={label}
        minHeight={minHeight}
        onClick={() => forwardedRef.current.click()}
        hidden={standalone ? false : hasAttachments}
      />
      <GaiaAddMoreButton
        text={text}
        onClick={() => forwardedRef.current.click()}
        hidden={standalone ? true : !hasAttachments || single}
      />
      <input
        id={'input'}
        type={'file'}
        ref={forwardedRef}
        multiple={!single}
        accept={filters?.types ? filters.types.join(',') : '*/*'}
        hidden
      />
    </Grid>
  )
}

export default useMemoRef(AttachmentUpload, [])
